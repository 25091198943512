<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.client" placeholder="客户" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in clientItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" placeholder="" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">查询</a-button>
      </a-col>

      <a-col :span="24" :md="4" :xl="4" style="margin-bottom: 12px">
        <a-button-group>
          <a-button icon="download" @click="exportExcel">导出</a-button>
        </a-button-group>
      </a-col>
      <div style="margin-bottom: 12px; float: right">
        <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd(form)">新增入库</a-button>
      </div>
    </a-row>
    <a-row gutter="16">
      <a-col :span="24">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :scroll="{ y: 480 }"
          :loading="loading"
          @change="onChangeTable"
        >
        </a-table>
      </a-col>
    </a-row>
    <add-modal ref="modal_add" @ok="initData"></add-modal>
  </div>
</template>
<script>
import { exportExcel } from "@/utils/excel";
import { stockInRecordsExport } from "@/api/export";
import { stockInAndOutInList } from "@/api/charging";
import { clientOption, warehouseOption } from "@/api/option";

export default {
  name: "StockInPanel",
  data() {
    return {
      loading: false,
      searchForm: { page: 1, page_size: 48, is_void: false },
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "入库通知单号",
          dataIndex: "receipt_order_number",
          key: "receipt_order_number",
        },
        {
          title: "仓库编号",
          dataIndex: "warehouse_number",
          key: "warehouse_number",
        },
        {
          title: "仓库名称",
          dataIndex: "warehouse_name",
          key: "warehouse_name",
        },
        {
          title: "客户编号",
          dataIndex: "client_number",
          key: "client_number",
        },
        {
          title: "客户名称",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "运输计费数量",
          dataIndex: "transport_record_item",
          key: "transport_record_item",
          customRender: (t, r, index) => {
            return r.transport_record_item ? r.transport_record_item.measure_value : 0;
          },
        },
        {
          title: "装卸计费数量",
          dataIndex: "handling_record_item",
          key: "handling_record_item",
          customRender: (t, r, index) => {
            return r.handling_record_item ? r.handling_record_item.measure_value : 0;
          },
        },
        {
          title: "仓储计费数量",
          dataIndex: "storage_record_item",
          key: "storage_record_item",
          customRender: (t, r, index) => {
            return r.storage_record_item ? r.storage_record_item.measure_value : 0;
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
          key: "creator_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
      ],
      warehouseItems: [],
      clientItems: [],
    };
  },
  components: {
    AddModal: () => import("./modules/addModal"),
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
        this.list();
      });
      clientOption({ page_size: 999999 }).then((data) => {
        this.clientItems = data.results;
      });
    },
    handleAdd() {
      this.$refs.modal_add.show();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    list() {
      this.loading = true;
      stockInAndOutInList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportExcel() {
      stockInRecordsExport()
        .then((resp) => {
          exportExcel(resp, "入库记录列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
